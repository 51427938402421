import { facadeApi } from 'api'
import { SharePointApp } from 'types/microSoftApp/microSoftApp'

export interface Params {
  tenantId?: string
}

export const fetchSharePointAppsListApi = ({ tenantId }: Params) =>
  facadeApi.get<SharePointApp[]>('/microsoft-app', {
    params: {
      tenant_id: tenantId,
    },
  })
