import { facadeApi } from 'api'
import { SharePointSite } from 'types/microSoftApp/microSoftApp'

export interface Params {
  tenantId: string
  appId: string
}

export const fetchSharePointSitesListApi = ({ tenantId, appId }: Params) =>
  facadeApi.get<SharePointSite[]>('/site', {
    params: {
      tenant_id: tenantId,
      ma_id: appId,
    },
  })
