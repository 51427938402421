import { TreeChangeEventDetail, TreeType } from '@platform-ui-kit/components-library'
import {
  WppTree,
  WppInput,
  WppSelect,
  WppPopover,
  WppDivider,
  WppSkeleton,
  WppActionButton,
} from '@platform-ui-kit/components-library-react'
import { useRef, useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { useDebounceFn } from 'hooks/useDebounceFn'
import styles from 'pages/newsList/filters/SourcesSelect.module.scss'
import { useSourceTreeData } from 'pages/utils'

export enum SOURCE_TYPE {
  APP = 'app',
  SITE = 'site',
}

const markAllAsSelect = (tree: TreeType[]): TreeType[] => {
  return tree.map(item => {
    if (item.children) {
      return { ...item, selected: true, children: markAllAsSelect(item.children) }
    }
    return { ...item, selected: true }
  })
}

const markAllAsUnSelect = (tree: TreeType[]): TreeType[] => {
  return tree.map(item => {
    if (item.children) {
      return { ...item, selected: false, children: markAllAsUnSelect(item.children) }
    }
    return { ...item, selected: false }
  })
}

export interface SelectedTreeItem extends TreeType {
  type: string
}

interface Props {
  onSelectHandler: (selectedItems: SelectedTreeItem[]) => void
}

export const SourcesSelect = ({ onSelectHandler }: Props) => {
  const popoverRef = useRef<HTMLWppPopoverElement>(null)

  const [search, setSearch] = useState<string>('')

  const { treeData, onChangeTreeHandler, isLoading } = useSourceTreeData()

  const setSearchDebounced = useDebounceFn((searchString?: string) => {
    setSearch(searchString?.trim() || '')
  }, 400)

  const onTreeChangeHandler = (detail: TreeChangeEventDetail) => {
    onSelectHandler(detail.selectedItems as SelectedTreeItem[])
    onChangeTreeHandler(detail.treeState)
  }

  const extractChildren = (arr: TreeType[]) => {
    let flatMap: SelectedTreeItem[] = []

    arr.forEach((item: any) => {
      flatMap.push(item, ...item.children)
    })

    return flatMap
  }

  const handleAllSelect = () => {
    const updatedTree = markAllAsSelect(treeData)
    onSelectHandler(extractChildren(updatedTree))
    onChangeTreeHandler(updatedTree)
  }

  const handleAllUnSelect = () => {
    const updatedTree = markAllAsUnSelect(treeData)
    onSelectHandler([])
    onChangeTreeHandler(updatedTree)
  }

  return isLoading ? (
    <WppSkeleton height={32} width={322} />
  ) : (
    <WppPopover
      ref={popoverRef}
      config={{
        triggerElementWidth: true,
        appendTo: () => document.querySelector('#micro-app') || document.body, //Required for eventListeners inside dropDown container
      }}
    >
      <WppSelect
        size="s"
        required
        type="multiple"
        slot="trigger-element"
        className={styles.select}
        placeholder="Application & news site"
        dropdownConfig={{ triggerElementWidth: false }}
      />
      <Flex direction="column" className={styles.dropdownWrapper}>
        <WppInput
          type="search"
          placeholder="Search"
          className={styles.searchInput}
          onWppChange={({ detail }) => setSearchDebounced(detail.value)}
        />
        <WppDivider />
        <WppTree
          multiple
          search={search}
          data={treeData}
          withItemsTruncation
          className={styles.treeWrapper}
          onWppChange={({ detail }) => onTreeChangeHandler(detail)}
        />
        <WppDivider />
        <Flex justify="between" className={styles.actions}>
          <WppActionButton onClick={handleAllSelect}>Select all</WppActionButton>
          <WppActionButton onClick={handleAllUnSelect}>Clear all</WppActionButton>
        </Flex>
      </Flex>
    </WppPopover>
  )
}
