import { TreeType } from '@platform-ui-kit/components-library'
import { useOs } from '@wpp-open/react'
import { useEffect, useState } from 'react'

import { useSharePointAppsListApi } from 'api/microSoftApp/useSharePointAppsListApi'
import { useSharePointSitesListApi } from 'api/microSoftApp/useSharePointSiteList'
import { useStableCallback } from 'hooks/useStableCallback'
import { SOURCE_TYPE } from 'pages/newsList/filters/SourcesSelect'
import { SharePointSite, SharePointApp } from 'types/microSoftApp/microSoftApp'

interface UseSourceTreeDataReturn {
  isLoading: boolean
  treeData: TreeType[]
  onChangeTreeHandler: (treeState: TreeType[]) => void
}

export const useSourceTreeData = (): UseSourceTreeDataReturn => {
  const { osContext } = useOs()
  const {
    tenant: {
      azMeta: { organizationsId: tenantId },
    },
  } = osContext

  const [sites, setSites] = useState<SharePointSite[]>([])
  const [treeData, setTreeData] = useState<TreeType[]>([])
  const [isSitesLoading, setIsSitesLoading] = useState(false)

  const { data: sharepointsApps, isLoading: isSharePointAppsLoading } = useSharePointAppsListApi({
    params: { tenantId },
  })

  const appSitesListHandler = useSharePointSitesListApi()

  const handleLoadSitesList = useStableCallback(async (apps: { id: string }[]) => {
    return await Promise.all(apps.map(({ id: appId }) => appSitesListHandler({ appId, tenantId }))).then(values =>
      values.map(({ data }) => data),
    )
  })

  const onChangeTreeHandler = useStableCallback((treeState: TreeType[]) => {
    setTreeData(treeState)
  })

  const mapDataToTree = useStableCallback(
    ({ app, children = [] }: { app: SharePointApp[]; children: SharePointSite[] }): TreeType[] => {
      const treeData: TreeType[] = []

      if (app?.length) {
        const mappedSources = new Map<string, TreeType[]>(app.map(app => [app.id, []]))
        children.forEach(site => {
          const parent = mappedSources.get(site.ma_id)
          parent && parent.push({ title: site.name, id: site.id, selected: true, type: SOURCE_TYPE.SITE })
        })

        treeData.push(
          ...app.map(
            app =>
              ({
                open: true,
                id: app.id,
                title: app.name,
                type: SOURCE_TYPE.APP,
                children: mappedSources.get(app.id),
                selected: mappedSources.get(app.id)?.length,
                isNotSelectable: !mappedSources.get(app.id)?.length, //app without sites cannot be selected
                disabled: !mappedSources.get(app.id)?.length,
              } as TreeType),
          ),
        )
      }
      return treeData
    },
  )

  useEffect(() => {
    const loadSitesList = async () => {
      if (sharepointsApps?.length) {
        try {
          setIsSitesLoading(true)
          const result = await handleLoadSitesList(sharepointsApps)
          setSites(result.flat())
          setIsSitesLoading(false)
        } catch (error) {
          console.error(error)
        }
      }
    }
    loadSitesList()
  }, [sharepointsApps, handleLoadSitesList])

  useEffect(() => {
    if (sharepointsApps?.length && sites) {
      setTreeData(() => mapDataToTree({ app: sharepointsApps, children: sites }))
    }
  }, [sharepointsApps, sites, mapDataToTree])

  return {
    treeData,
    onChangeTreeHandler,
    isLoading: isSharePointAppsLoading || isSitesLoading,
  }
}
