import { MayBeNull } from '@wpp-open/core'

import { facadeApi } from 'api'
import { NEWS_STATUSES } from 'pages/newsList/NewsList'
import { News } from 'types/news/news'

export interface Params {
  search: string
  tenantId: string
  siteIds?: string[]
  statuses?: NEWS_STATUSES[]
  countryAlpha2Codes: string[]
  is_primary: MayBeNull<boolean>
}

export const fetchNewsApi = ({ tenantId, countryAlpha2Codes, siteIds, is_primary, search, statuses }: Params) =>
  facadeApi.post<News[]>(
    '/news/search',
    {
      ...(!!search && { search }),
      ...(!!statuses && { statuses }),
      ...(!!countryAlpha2Codes?.length && { countryAlpha2Codes }),
      ...(!!siteIds?.length && { siteIds }),
      ...(!!countryAlpha2Codes?.length && { includeNoCountries: false }), //Need to be fixed, Max is aware
      ...(is_primary !== null && { is_primary }),
    },
    {
      params: {
        tenant_id: tenantId,
      },
    },
  )
