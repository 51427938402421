// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sUaea{width:322px}.xXDUJ{flex:1 1}.n53WD::part(input){width:100%;border-color:rgba(0,0,0,0)}.zdPkh{padding:8px}.h9Nz2{z-index:100;padding:8px}", "",{"version":3,"sources":["webpack://./src/pages/newsList/filters/SourcesSelect.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CAGF,OACE,QAAA,CAIA,oBACE,UAAA,CACA,0BAAA,CAIJ,OACE,WAAA,CAGF,OACE,WAAA,CACA,WAAA","sourcesContent":[".select {\n  width: 322px;\n}\n\n.dropdownWrapper {\n  flex: 1;\n}\n\n.searchInput {\n  &::part(input) {\n    width: 100%;\n    border-color: transparent;\n  }\n}\n\n.treeWrapper {\n  padding: 8px;\n}\n\n.actions {\n  z-index: 100;\n  padding: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": "sUaea",
	"dropdownWrapper": "xXDUJ",
	"searchInput": "n53WD",
	"treeWrapper": "zdPkh",
	"actions": "h9Nz2"
};
export default ___CSS_LOADER_EXPORT___;
