import { facadeApi } from 'api'
import { News, NewsItemUpdateDTO } from 'types/news/news'

export interface Params {
  newsId: string
  tenantId: string
  newsItem: NewsItemUpdateDTO
}

export const updateNewsItemApi = ({ tenantId, newsItem, newsId }: Params) =>
  facadeApi.patch<News>(
    `/news/${newsId}`,
    { ...newsItem },
    {
      params: {
        tenant_id: tenantId,
      },
    },
  )
